/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type InsuranceContractTemplateDetailFragment = {
  __typename?: 'VehicleInsuranceContractTemplate';
  id: any;
  templateName: string;
  company?: Types.VehicleInsuranceContractCompanyChoices | null;
  insuranceType?: Types.VehicleInsuranceTypeChoices | null;
  contractType?: Types.VehicleInsuranceContractTypeChoices | null;
  startDate?: any | null;
  endDate?: any | null;
  policyNumber: string;
  proposalNumber: string;
  meAllowedOperations: Array<Types.PermissionOperationChoices>;
  paidAmountInCzk?: any | null;
  paymentFrequency?: Types.VehicleInsuranceContractPaymentFrequencyChoices | null;
  vehicles: Array<{ __typename?: 'Vehicle'; id: any }>;
};

export type InsuranceContractTemplateDetailQueryVariables = Types.Exact<{
  vehicleInsuranceContractTemplateDetailId: Types.Scalars['VehicleInsuranceContractTemplateID']['input'];
}>;

export type InsuranceContractTemplateDetailQuery = {
  __typename?: 'Query';
  object:
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null }
    | {
        __typename?: 'VehicleInsuranceContractTemplate';
        id: any;
        templateName: string;
        company?: Types.VehicleInsuranceContractCompanyChoices | null;
        insuranceType?: Types.VehicleInsuranceTypeChoices | null;
        contractType?: Types.VehicleInsuranceContractTypeChoices | null;
        startDate?: any | null;
        endDate?: any | null;
        policyNumber: string;
        proposalNumber: string;
        meAllowedOperations: Array<Types.PermissionOperationChoices>;
        paidAmountInCzk?: any | null;
        paymentFrequency?: Types.VehicleInsuranceContractPaymentFrequencyChoices | null;
        vehicles: Array<{ __typename?: 'Vehicle'; id: any }>;
      };
};

export const InsuranceContractTemplateDetailFragmentDoc = gql`
  fragment InsuranceContractTemplateDetail on VehicleInsuranceContractTemplate {
    id
    templateName
    company
    insuranceType
    contractType
    startDate
    endDate
    policyNumber
    proposalNumber
    meAllowedOperations
    paidAmountInCzk
    paymentFrequency
    vehicles {
      id
    }
  }
`;
export const InsuranceContractTemplateDetailDocument = gql`
  query InsuranceContractTemplateDetail(
    $vehicleInsuranceContractTemplateDetailId: VehicleInsuranceContractTemplateID!
  ) {
    object: vehicleInsuranceContractTemplateDetail(id: $vehicleInsuranceContractTemplateDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on VehicleInsuranceContractTemplate {
        ...InsuranceContractTemplateDetail
      }
    }
  }
  ${InsuranceContractTemplateDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class InsuranceContractTemplateDetailGQL extends Apollo.Query<
  InsuranceContractTemplateDetailQuery,
  InsuranceContractTemplateDetailQueryVariables
> {
  override document = InsuranceContractTemplateDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
